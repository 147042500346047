import { IEnvironment } from '@common/types/environment';

export const environment: IEnvironment = {
  production: true,
  keycloak: {
    url: 'https://sso.tb-drone.ru/auth/',
    realm: 'ucp',
    clientId: 'base'
  },
  api: 'https://api-ptkdko.tb-drone.ru',
  calendarApi: 'https://api-calendar.tb-drone.ru',
  constructorApi: 'https://forms.tb-drone.ru',
  taskManagerApi: 'https://api-track.tb-drone.ru',
  wsHost: 'wss://centrifugo.tb-drone.ru',
  jitsuLogging: {
    hostUrl: 'https://jitsu.tb-drone.ru',
    apiKey: 'E0iw00Mew6VStwoFpBPzTd4wte4Yuw2o:tPSqhLDvWb7DtaYg9aWoS0jHfNssAs4A'
  },
  jitsiUrl: 'meet.tb-drone.ru',
  wiki: 'https://wiki.tb-drone.ru'
};
